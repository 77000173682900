<template>
	<w-alert :outline="outline" :value="true" color="warning" icon="warning">{{ text }}</w-alert>
</template>

<script>
/**
 * @displayName w-warning
 *
 * @since 0.3.2
 */
export default {
	name: 'WWarning',
	props: {
		text: {
			required: true,
			type: String
		},
		outline: {
			default: false,
			required: false,
			type: Boolean
		}
	}
}
</script>
